.cont {
    height: 100px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    margin-top: 200px;
    align-items: center;
    opacity: 0.8;
  }
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #3F51B5;
    border-color: #3F51B5 transparent #3F51B5 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  