.drop-area {
    width: 122px;
    height: 158px;
    position: relative;
    font-size: 18px;
    color:#3354b5;
    border: 1px dashed #3354b5;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.drop-area:hover {
    background-color: rgb(228, 227, 227);
}
.drop-area:active {
    background-color: rgb(209, 209, 209);
}

.drop-area input[type=file] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
}

.drag-area {
    background-color: rgb(209, 209, 209);
}
.text-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}